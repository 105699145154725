<template>
  <div>
    <div style="background:white;width:92%;margin-left:4%;margin-top:16px;border-radius: 10px;">
       <a-row >
        <a-col :span="1"> </a-col>
        <a-col :span="22">
          <h2 class="title">
            零元预约
          </h2>
          <h2 class="title ">
           体验智能财务SaaS系统
          </h2>
          <div  :class="this.transition ? 'answer' : 'answer2'" @click="changeshowPhone()">
             <img src="../../assets/answer.png" alt="">
             <div class="phone" v-if="this.transition && this.showPhone">
                13472858253
             </div>
          </div>
          <a-form-model
            ref="form"
            :model="formModel"
            :rules="formRules"
            @submit="submitClick"
            @submit.native.prevent
            style="margin: 0 auto;background:white;padding:20px 16px;border-radius:20px;"
          >
            <a-form-model-item prop="customerCompany">
              <a-input
                v-model="formModel.customerCompany"
                size="large"
                class="inputApply"
                placeholder="公司名称"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item prop="customerName">
              <a-input
                v-model="formModel.customerName"
                size="large"
                class="inputApply"
                placeholder="姓名"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="customerPhone">
              <a-input
                v-model="formModel.customerPhone"
                size="large"
                class="inputApply"
                placeholder="手机号"
                :maxLength="11"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="captcha">
              <a-input
                v-model="formModel.captcha"
                placeholder="验证码"
                size="large"
                class="inputApply"
                :maxLength="6"
                style="width:100%;"
              >
                <template slot="suffix">
                  <cs-countdown
                    text="获取验证码"
                    ref="CsCountdown"
                    class="cursor-pointer"
                    :validate="validateCode"
                    style="color: #00a497"
                    v-slot="{ time }"
                    >{{ time }}s</cs-countdown
                >
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                class="btn-submit"
                size="large"
                block
                style="font-weight:500"
                html-type="submit"
              >
                提交申请
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </div>

    <div
      class="apply-toast position-fixed top-50 start-50 translate-middle d-flex"
      v-if="applyResult > 0"
    >
      <template v-if="applyResult == 1">
        <a-icon
          type="check-circle"
          style="font-size: 54px; color: #00a497; margin-right: 20px"
          theme="filled"
        />
        <div>
          <h3>申请已提交</h3>
          <span>我们将尽快和您联系~</span>
        </div>
      </template>
      <template v-else>
        <a-icon
          type="info-circle"
          style="font-size: 54px; color: #feae39; margin-right: 20px"
          theme="filled"
        />
        <div>
          <h3>您的申请已受理</h3>
          <span>24小时内请勿重复提交~</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formModel: {
        customerCompany: "",
        customerName: "",
        customerPhone: "",
        captcha: "",
        timer: null,
      },
      
      formRules: {
        customerCompany: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入公司名称"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        customerName: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入姓名"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        customerPhone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号码"));
              }
              if (!this.api.validate.isPhoneNumber(value)) {
                return callback(new Error("请输入正确的手机号码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (!value || value.length != 6) {
                return callback(new Error("请输入6位验证码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
      },
      applyResult: 0,
      transition: true, 
      showPhone:false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滑动
  },
  methods: {
    // 获取验证码
    async validateCode() {
      const { customerPhone } = this.formModel;
      if (!customerPhone || !this.api.validate.isPhoneNumber(customerPhone)) {
        this.$message.error("请输入正确的手机号");
        throw new Error("请输入正确的手机号");
      }
      const res = await this.api.service.fpc_oapi_customer_getCaptcha(
        {},
        { customerPhone }
      );
      if (res.code == "20801") {
        return this.showApplyMsg(2);
      }
      await this.api.toast(res, 0);
    },
    submitClick() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const res = await this.api.service.fpc_oapi_customer_submit(
            this.formModel,
            {}
          );
          if (res.code == "20801") {
            return this.showApplyMsg(2);
          }
          await this.api.toast(res, 0);
          this.showApplyMsg(1);
        }
      });
    },
    changeshowPhone(){
      this.showPhone = !this.showPhone;
    },
    handleScroll () {
      this.transition = false
      this.showPhone = false
      if (this.timer) { // 判断是否已存在定时器
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.transition = true
      }, 500)
    },
    // 重置数据
    resetData() {
      this.formModel.customerCompany = "";
      this.formModel.customerName = "";
      this.formModel.customerPhone = "";
      this.formModel.captcha = "";
    },
    closeClick() {
      this.resetData();
      this.$refs.form.clearValidate();
      const fixedApply = this.$el.querySelector(".fixed-apply");
      const fixedBar = this.$el.querySelector(".fixed-bar");
      fixedApply.classList.add("animate__fadeOutRightBig");
      fixedBar.classList.add("d-none");
      setTimeout(() => {
        fixedBar.classList.remove("d-none");
        fixedBar.classList.add("animate__fadeInUp");
      }, 500);
    },
    openClick() {
      const fixedBar = this.$el.querySelector(".fixed-bar");
      fixedBar.classList.add("d-none");

      const fixedApply = this.$el.querySelector(".fixed-apply");
      fixedApply.classList.remove("d-none", "animate__fadeOutRightBig");
      fixedApply.classList.add("animate__fadeInRightBig");
    },
    showApplyMsg(result) {
      this.applyResult = result;
      this.resetData();
      setTimeout(() => {
        this.applyResult = -1;
      }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
body{
  background:white;
}
.apply-toast {
    width: 94%;
    margin-left:3%;
    padding: 32px;
    background: #ffffff;
    border-radius: 15px;
    h3 {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      font-weight: 500;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      font-weight: 400;
    }
  }
  .inputApply {
    background: #F7F8FA;
    color: #3e4c5a;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /deep/ .ant-input-affix-wrapper .ant-input:not(:last-child){
    background: #F7F8FA;
    color: #3e4c5a;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title{
    text-align: center;
    margin-top:1rem;
    font-size:18px;
    font-weight:700;
  }
  .answer{
    height:60px;
    width:60px;
    position:fixed;
    z-index:1;
    right:0;
    top:55%;
    border-radius: 50%;
    .phone{
      height:60px;
      width:200px;
      margin-left:10%;
      position:absolute;
      right:100%;
      top:0;
      background:white;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    img{
      height:100%;
      width:100%;
      border-radius: 20%;
    };
  }
  .answer2{
    position:fixed;
    z-index:1;
    right:-60px;
    transform: scale(0.5);
    top:50%;
    opacity: 0.5;
    img{
      height:100%;
      width:100%;
      border-radius: 20%;
    };
  }
 
  .saoma{
    width:90%;
    margin-left:5%;
    border-radius: 15px;;
    margin-top:10px;
    height:auto;
    padding:40px 0 ;
    background: white;;
    display:flex;
    .qrcode{
        width:50%;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        :nth-child(2){
          font-family: "PingFang SC";
          padding:20px 0 4px 0;
          color: #041334;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }
        :nth-child(3){
          color: #666c78;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        img{
            width:50%;
            height:50%;
        }
    }
  }


</style>